// suncentral-account-manager:	    A user who manages one or more subscriber accounts.
// suncentral-account-read-only:	  A user who has read-only access to a subscriber.
// suncentral-admin:	              A user will full admin access to a suncentral shard.
// suncentral-admin-notifications:	An admin user who receives notifications emails.
// suncentral-portfolio-manager:	  A user who manages portfolios in SunCentral.
// suncentral-prospect:	            A user who is a subscriber prospect.
// suncentral-subscriber:	          A user who is a subscriber to a SunCentral Shard.
//
export default Object.freeze({
  // TODO:
  // Old lookups (backwards compatibility for Auth0 release can eventually remove)
  admin: 'Admin',
  accountManager: 'Account Manager',
  accountReadOnly: 'Read Only',
  portfolioManager: 'Portfolio Manager',
  prospect: 'Prospect',
  subscriber: 'Subscriber',

  // Lookup by name to type
  Admin: 'suncentral-admin',
  'Account Manager': 'suncentral-account-manager',
  'Read Only': 'suncentral-account-read-only',
  'Portfolio Manager': 'suncentral-portfolio-manager',
  Prospect: 'suncentral-prospect',
  Subscriber: 'suncentral-subscriber',

  // Lookup by type to name
  'suncentral-admin': 'Admin',
  'suncentral-account-manager': 'Account Manager',
  'suncentral-account-read-only': 'Read Only',
  'suncentral-portfolio-manager': 'Portfolio Manager',
  'suncentral-prospect': 'Prospect',
  'suncentral-subscriber': 'Subscriber'
});
